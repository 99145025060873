import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from '../translate/en.json';
import translationFR from '../translate/fr.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};

// const currentLang = "fr";
const currentLang = localStorage.getItem('currentLang');

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: currentLang,
  // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;