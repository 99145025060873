import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './utils/i18n';
import App from './scripts/App';
import * as serviceWorker from './serviceWorker';

if(localStorage.getItem('currentLang') == null){
    localStorage.setItem('currentLang', 'en');
}
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
