import React , { Component } from 'react';

class NumberOfPerson extends Component {
  constructor(props){
    super(props);
  }

  render() {

    return (
        <div className="number-select">
            <div className="btn-number btn-moins" onClick={() => this.props.RedNbPerson()}></div>
            <div className="number">{this.props.NbPerson}</div>
            <div className="btn-number btn-plus" onClick={() => this.props.AddNbPerson()}></div>
        </div>
    )
  }
}

export default NumberOfPerson;