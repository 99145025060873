import React , { Component } from 'react';
import MenuTeaser from "./MenuTeaser.js";
import ModalChoiceMenu from "./ModalChoiceMenu.js";
import Slider from "react-slick";
import {API_URL, HEADER_API_URL} from '../../utils/config';
import moment from 'moment';


class BreakfastsMenu extends Component {

  constructor(props){
    super(props);
    // this.GetNidMenuItem = this.GetNidMenuItem.bind(this);
    this.state = {
      idMenuItem : "",
      show_modal : false,
      infos: null,
      base_url: null,
      show_modal_restrict: false,
    }
  }

  componentDidMount() {
    fetch(API_URL.resort,{
      method: 'GET',
      headers: {
        "Accept-Language": HEADER_API_URL.accept_language,
        "x-api-key": HEADER_API_URL.api_key
      },
    })
    .then((res) => {
      if (!this.state.base_url) {
        this.setState({ base_url: res.headers.get("content-base-url") })
      }
      return res.json();
    }).then((data) => {
      if (!this.state.infos) {
        this.setState({ infos: data }, () => {
          // console.log('inforesort',this.state.infos);
        })
      }
    })
    .catch(console.log)
  }
  

  GetNidMenuItem = (NidMenuItem) => {
    this.setState({ idMenuItem: NidMenuItem });
    this.ShowModalMenuItem();
  };

  ShowModalMenuItem = () =>  {
    this.setState({ show_modal: true });
  };

  HideModalMenuItem = () => {
    this.setState({ show_modal: false });
  };

  ModalRestrict = () => {
    this.setState({ show_modal_restrict: !this.state.show_modal_restrict });
  };

  render() {
    if(!this.state.infos){
      return '';
    }

    var nbMenuSlider;
    const nbMenuSliders = this.props.MenuResult && this.props.MenuResult.map((menublock, i) => { nbMenuSlider = menublock.field_content.length });
    
    var settingsSlider = {
      dots: true,
      infinite: nbMenuSlider > 3 ? true : false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        }
      ]
    };
    const classShowModal = this.state.show_modal ? "show-modal" : "hide-modal";

    const classShowModalRestrict = this.state.show_modal_restrict ? "show-modal" : "hide-modal";

    const varT= this.props.varT;

   
    const OpeningTimeResort = moment.utc( this.state.infos.field_roomservice_opening_time * 1000 ).format(varT("format_activity_start_date"));
    const ClosingTimeResort = moment.utc(this.state.infos.field_roomservice_closing_time  * 1000).format(varT("format_activity_start_date"));
    
    return (
      <div className="menu-breakfasts-block">
        {this.props.MenuResult && this.props.MenuResult.map((menublock, i) => {
            return (
              <div className="menu-breakfasts" key={i} menublock={menublock}>
                  <div className="title-menu-breakfasts">{menublock.field_title}</div>
                  <div className="menu-block">
                    <Slider {...settingsSlider}>
                        {menublock.field_content && menublock.field_content.map((menuitem, x) => {     
                          // if(menublock.field_content.length < 3){
                          //   console.log("pas bp")
                          // }
                        return (
                            <MenuTeaser key={x} MenuItem={menuitem} BaseUrl={this.props.BaseUrl} NidMenuItem={this.GetNidMenuItem} IndexItem={x} varT={this.props.varT} />
                          ) 
                        })}
                      </Slider>
                      <ModalChoiceMenu classNameModal={classShowModal} InfoItemForModal={menublock.field_content[this.state.idMenuItem]} 
                        CloseModal={this.HideModalMenuItem} BaseUrl={this.props.BaseUrl} varT={this.props.varT} infoOpen={this.state.infos} doModalRestrict={this.ModalRestrict}/>
                  </div>
              </div>
            ) 
        })}
        <div className={"modal-restrict "+ classShowModalRestrict}>
          <div className="content-modal">
            <div className="title">{varT("room_services_popin_unavailable")}</div>
            <div className="text">{varT("room_services_popin_unavailable_text", { start: OpeningTimeResort, end: ClosingTimeResort})}</div>       
            <div className="btn yellow btn-close" onClick={this.ModalRestrict}><span>{varT("room_services_popin_unavailable_cta")}</span></div>     
          </div>
        </div>
      </div>
    )
  }
}

export default BreakfastsMenu;