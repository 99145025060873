import React , { Component } from 'react';
import { navigate } from "@reach/router";
import {paramUrl} from '../../utils/config';

class HeaderChoice extends Component {
  constructor(props){
    super(props);
  }

  GoHome = () => {
    localStorage.removeItem('menuChoice');
    localStorage.removeItem('date_delivery');
    localStorage.removeItem('idMenuChoiceClient');
    localStorage.removeItem('nbperson');
    localStorage.removeItem('product_list');
    localStorage.removeItem('product_list_info');
    localStorage.removeItem('email');
    localStorage.removeItem('phone');
    localStorage.removeItem('roomId');
    localStorage.removeItem('date_delivery_to');
    localStorage.removeItem('date_delivery_from');
    navigate(`/`+paramUrl);
    window.location.reload();
  }
  render() {
    if (!this.props.BaseUrl) {
      return '';
    }
    const LStorage = JSON.parse(localStorage.getItem("menuChoice"));
    const linkBkgW = LStorage.field_image.file.url.replace("/sites/default/files/","");
    const headerChoiceBkg = {backgroundImage: 'url('+ this.props.BaseUrl + '/sites/default/files/styles/card_big/public/' + linkBkgW +')'}
    
    return (
      <div className="header-choice-block">
        <div className="header-choice-bkg" style={headerChoiceBkg}>
          <div className="title-block">
              <div className="goback-arrow" onClick={this.GoHome}></div>
              <div className="title">{LStorage.title}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderChoice;