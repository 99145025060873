import React , {Component} from 'react';

const options = [
  { value: 'en', label: 'En' },
  { value: 'fr', label: 'Fr' },
];

class MenuLanguage extends Component {

  state = {
    selectedOption: localStorage.getItem('currentLang'),
    showlang : false,
  };

  SetLaguage = (selectedLang) => {
    this.setState({ selectedOption : selectedLang });
    localStorage.setItem('currentLang',selectedLang);
    window.location.reload();
  };

  ShowLang = () =>  {
    this.setState({ showlang: !this.state.showlang });
  };

  render () {
    const classShowLang = this.state.showlang ? "show" : "hide";
    return (
      <div className={"menu-language-block "  + classShowLang}>
          <div className="block-langage">
            <div className="arrow-lang"></div>
            <div className="current-lang" onClick={() => this.ShowLang()}>{this.state.selectedOption}</div>
          </div>
            <ul className="menu-language">
              {options.map((optionlang, x) => {     
                return (
                  <li key={x} onClick={() => this.SetLaguage(optionlang.value)}>{optionlang.value !== this.state.selectedOption && optionlang.value}</li>                           
                ) 
              })}
            </ul>
      </div>
    )
  } 
}

export default MenuLanguage;