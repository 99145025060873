import React , { Component } from 'react';
// import Moment from 'react-moment';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { navigate } from "@reach/router";
import {paramUrl,roomId} from '../../utils/config';
import SimpleBar from 'simplebar';

class ModalChoiceMenu extends Component {

  constructor(props){
    super(props);
  }

  FinalChoiceMenu = (infoMenuChoice) => {
    // const OpeningTimeResort = this.props.infoOpen.field_roomservice_opening_time;
    // const ClosingTimeResort = this.props.infoOpen.field_roomservice_closing_time;
    // const CurrentHour = moment().format('H') * 3600;

    const OpeningTimeResort = parseInt(moment.utc( this.props.infoOpen.field_roomservice_opening_time * 1000 ).format("Hmm"));
    const ClosingTimeResort = parseInt(moment.utc(this.props.infoOpen.field_roomservice_closing_time  * 1000).format("Hmm"));
    const CurrentHour = parseInt(moment().format("Hmm"));

    console.log('Opening:'+OpeningTimeResort
    ,'Current:'+CurrentHour ,'Closing:'+ClosingTimeResort)

    if(OpeningTimeResort <= CurrentHour && CurrentHour <= ClosingTimeResort){
      localStorage.setItem("menuChoice", JSON.stringify(infoMenuChoice));
      localStorage.setItem("idMenuChoiceClient", infoMenuChoice.nid);
      navigate(`/choice`+paramUrl)
    }else{
        this.props.doModalRestrict();
    }
  }

  render() {
    const varT = this.props.varT;
    if(this.props.InfoItemForModal == null){
      return '';
    }
    const linkBkgMenuItem = this.props.InfoItemForModal.field_image.file.url.replace("/sites/default/files/","");
    const MenuItemBkg = {backgroundImage: 'url('+ this.props.BaseUrl + '/sites/default/files/styles/card_big/public/' + linkBkgMenuItem +')'}
    const DeliveryFrom = this.props.InfoItemForModal.field_ordering_hours.from * 1000;
    const DeliveryTo = this.props.InfoItemForModal.field_ordering_hours.to * 1000; 
    const DeliveryFromInH = moment.utc(DeliveryFrom).format(varT("format_activity_start_date"));
    const DeliveryToInH = moment.utc(DeliveryTo).format(varT("format_activity_start_date"));

    return (
      <div className={"modal-choice-menu-item " + this.props.classNameModal}>
          <div className="cross-close" onClick={() => this.props.CloseModal()}></div>
          <div className="block-menu-item">
            <div className="picture-menu-item" style={MenuItemBkg}></div>
            <div className="info-menu-item">
                <div className="title-menu-item">{this.props.InfoItemForModal.title}</div>
                <div className="block-delivery">
                    <div className="picto"></div>
                    <div className="info-delivery">{varT('order_detail_delivered_from', { opening_hours: DeliveryFromInH, closing_hours: DeliveryToInH})}</div>
                </div>
                <div className="block-text" data-simplebar data-simplebar-auto-hide="false">
                  <ReactMarkdown className="description-menu-item" source={this.props.InfoItemForModal.field_description} escapeHtml={false} />
                  <ReactMarkdown className="allergen-menu-item" source={this.props.InfoItemForModal.field_allergen} escapeHtml={false}/>
                </div>
                <div className="btn yellow btn-order-modal-menu-item" onClick={() => this.FinalChoiceMenu(this.props.InfoItemForModal)}>
                    <span>{varT('cta_order')}</span>
                </div>
            </div>
          </div>
      </div>
    )
  }
}

export default ModalChoiceMenu;