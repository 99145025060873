import React , { Component } from 'react';
import MenuLanguage from './MenuLanguage';

class HeaderHome extends Component {
  constructor(props){
    super(props);
  }

  render() {
    if(this.props.BkgHeader == null){
      return '';
    }

    const linkBkgW =this.props.BkgHeader.replace("/sites/default/files/","");
    const linkPictoW = this.props.PictoRoomService;
    const headerHomeBkg = {backgroundImage: 'url('+ this.props.BaseUrl + '/sites/default/files/styles/card_big/public/' + linkBkgW +')'}
    const PictoBkg = {background: 'url('+ this.props.BaseUrl + linkPictoW +')'}
  
    return (
      <div className="header-home-block">
        <div className="header-home-bkg" style={headerHomeBkg}>
          <div className="title-block">
              <div className="cloche-picto" style={PictoBkg}>
              </div>
              <div className="title">{this.props.TitleRoomService}</div>
          </div>
          <MenuLanguage/>
        </div>
      </div>
    )
  }
}

export default HeaderHome;