import React , { Component } from 'react';

class MenuTeaser extends Component {

  constructor(props){
    super(props);
  }



  render() {
    if(this.props.MenuItem.field_image == null){
      return '';
    }
    const linkBkgMenuItem = this.props.MenuItem && this.props.MenuItem.field_image.file.url.replace("/sites/default/files/","");
    const MenuItemBkg = {backgroundImage: 'url('+ this.props.BaseUrl + '/sites/default/files/styles/card_big/public/' + linkBkgMenuItem +')'}
    const varT = this.props.varT;
    return (
      <div className="menu-item-block" style={MenuItemBkg}>
        <div className="title-menu-item">{this.props.MenuItem && this.props.MenuItem.title}</div>
        <div className="btn yellow btn-order-menu-item" onClick={() => this.props.NidMenuItem(this.props.IndexItem)}>
          <span>{varT('cta_order')}</span>
        </div>
      </div>
    )
  }
}

export default MenuTeaser;