import React , { Component } from 'react';
import {API_URL, HEADER_API_URL} from '../../utils/config';
import HeaderChoice from '../component/HeaderChoice';
import MenuProduct from '../component/MenuProduct';

class Choice extends Component {

  constructor(props){
    super(props);
  }

  state = {
    menu_product: null,
    base_url: null
  }

  componentDidMount() {
    fetch(API_URL.menu_product,{
      method: 'GET',
      headers: {
        "Accept-Language": HEADER_API_URL.accept_language,
        "x-api-key": HEADER_API_URL.api_key
      },
    })
    .then((res) => {
      if (!this.state.base_url) {
        this.setState({ base_url: res.headers.get("content-base-url") })
      }
      return res.json();
    }).then((data) => {
      if (!this.state.menu_product) {
        this.setState({ menu_product: data }, () => {
          // console.log('resultchoice',this.state.menu_product);
        })
      }
    })
    .catch(console.log)
  }


  render() {
    return (
      <div className="page-choice">
        <HeaderChoice BaseUrl={this.state.base_url}/>
        <MenuProduct  MenuProduct={this.state.menu_product} varT={this.props.varT} BaseUrl={this.state.base_url}/>
      </div>
    );
  }
}

export default Choice;
  