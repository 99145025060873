import React , { Component } from 'react';
import NumberOfItemProduct from './NumberOfItemProduct';
import NumberOfPerson from './NumberOfPerson';
import DeliveryTime from './DeliveryTime';
import { navigate } from "@reach/router";
import {paramUrl} from '../../utils/config';

var LStorage = "";
var nIdMenuProdAll = {};
class MenuProduct extends Component {
  constructor(props){
    super(props);
    LStorage = JSON.parse(localStorage.getItem("menuChoice"));
  }
  state = {
    product_list : {},
    product_list_info : {},
    nbperson: 1,
  }

  GetTitleMenuProduct = (menuprod) => {
    if (!menuprod) {
      return '';
    }
    this.nIdMenuProdAll = menuprod.reduce((result,item) => {
      result[item.nid] = item.title;
      return result;
    }, {});
  }

  AddNbPerson = () => {
    if(this.state.nbperson < 6){
      this.setState({ nbperson: ++this.state.nbperson })
    }
  }
  RedNbPerson = () => {
    if(this.state.nbperson > 1){
      this.setState({ nbperson: --this.state.nbperson})
    }
  }

  GetInfoNbProduct = (idmenuprod, nbitem, labelprod) => {
    if(nbitem !== 0){
      this.setState({...this.state.product_list[idmenuprod] = nbitem});
      this.setState({...this.state.product_list_info[labelprod] = nbitem});
    }
  }

  GetTimeSLot = (timeslots,timeslotsfrom,timeslotsto) => {
    localStorage.setItem("date_delivery", timeslots);    
    localStorage.setItem("date_delivery_from", timeslotsfrom);  
    localStorage.setItem("date_delivery_to", timeslotsto);   
  }
  
  SubmitChoice = () => {
    localStorage.setItem("nbperson", this.state.nbperson);
    localStorage.setItem("product_list", JSON.stringify(this.state.product_list));
    localStorage.setItem("product_list_info", JSON.stringify(this.state.product_list_info));
    navigate(`/contact`+paramUrl);
  }

  render() {
    const varT = this.props.varT;  
    const MenuProducts = this.props.MenuProduct;  
    this.GetTitleMenuProduct(MenuProducts);
    return (
      <div className="menu-product-block">
        <div className="menu-info-general menu-info">
            <div className="title-menu-product">{varT('room_services_form_info_delivery')}</div>
            <div className="details-block">
                <div className="delivery-time choice-block">
                    <div className="label">{varT('room_services_form_info_delivery_time')}</div>
                    <div className="time-select">
                      <DeliveryTime varT={this.props.varT} TimeSlot={this.GetTimeSLot}/>
                    </div>
                </div>
                <div className="nb-person choice-block">
                    <div className="label">{varT('room_services_form_info_delivery_nb_person')}</div>
                    <NumberOfPerson NbPerson={this.state.nbperson} AddNbPerson={this.AddNbPerson} RedNbPerson={this.RedNbPerson}/>
                </div>
            </div>
        </div>
        <div className="menu-info-product menu-info">
            <div className="title-menu-product">{varT('room_services_form_info_quantity')}</div>
              {LStorage.field_menu_sections.map((menuproduct, x) => {
                  if(menuproduct.field_picto == null){
                    var PictoProduct = {display: 'none'}
                  }else{
                    if (!this.props.BaseUrl) {
                      return '';
                    }
                    PictoProduct = {background: 'url('+ this.props.BaseUrl + menuproduct.field_picto +')'}
                  }
                  return (
                    <div className="details-block" key={x}>
                      <div className="title-prod-type">
                          <div className="picto-prod-type" style={PictoProduct}></div>
                          {menuproduct.field_title}
                      </div>
                      {menuproduct.field_products_nids.map((menuproductdet, t) => {
                        if (!this.nIdMenuProdAll) {
                          return '';
                        }
                        return (
                        <div className="choice-block" key={t}>
                            <div className="label">
                              {this.nIdMenuProdAll[menuproductdet]}
                            </div>
                            <NumberOfItemProduct idItemProd={menuproductdet} labelMenuProd={this.nIdMenuProdAll[menuproductdet]} GetInfoNbProduct={this.GetInfoNbProduct}/>
                        </div>
                        )
                      })
                      }
                    </div>
                  )
                })
              }
        </div>
        <div className="submit-block">
            <div className="btn yellow btn-submit" onClick={() => this.SubmitChoice()}><span>{varT('room_services_cta_next')}</span></div>
            <div className="dots"><div></div><div></div></div> 
        </div>
      </div>
    )
  }
}

export default MenuProduct;