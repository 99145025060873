import React , { Component } from 'react';
import MenuLanguage from './MenuLanguage';
import { navigate } from "@reach/router";
import {paramUrl} from '../../utils/config';


var PlaceholderRoom;
var Disabled = "";
var LStorageRoom = localStorage.getItem("roomId");

class FormContact extends Component {
  constructor(props){
    super(props);
  }

  state = {
    email : '',
    phone : '',
    roomId: '',
  }

  componentDidMount = () => {
    this.setRoomId();
  }

  GetRoomId = (e) => {
    this.setState({roomId:  e.target.value});
  }

  GetPhone = (e) => {
    this.setState({phone:  e.target.value});
  }

  GetMail = (e) => {
    this.setState({email:  e.target.value});
  }

  SubmitChoice = () => {
    if(this.state.roomId === '' || this.state.roomId === null){
      return 'RoomId Required';
    }
    localStorage.setItem("email", this.state.email);
    localStorage.setItem("phone", this.state.phone);
    localStorage.setItem("roomId", this.state.roomId);
    navigate(`/commande`+paramUrl);
  } 

  setRoomId = () => {
    if(LStorageRoom !== null && LStorageRoom !== ""){
      if (!this.state.roomId) {
        this.setState({roomId : LStorageRoom});
      }
      PlaceholderRoom = LStorageRoom;
      Disabled = "disabled";
    }else{
      const varT = this.props.varT; 
      PlaceholderRoom = varT('room_services_form_confirm_room_number');
    }
  }

  render() {
    
    const varT = this.props.varT;  
    

    return (
      <div className="form-contact-block">
        <div className="title-form-contact">{varT('room_services_form_confirm_title')}</div>
        <div className="form-contact">
            <div className="room-input-block">
              <input type="text" className="input-form room" placeholder={PlaceholderRoom} onChange={this.GetRoomId} disabled={Disabled}/>
              <label>{varT('room_services_form_confirm_room_number_label')}</label>
            </div>
            <input type="text" className="input-form phone" placeholder={varT('room_services_form_confirm_room_phone')} onChange={this.GetPhone}/>
            <div className="block-separator">
                <span>{varT('room_services_form_confirm_room_separator')}</span>
            </div>
            <input type="email" className="input-form email" placeholder={varT('room_services_form_confirm_room_email')} onChange={this.GetMail}/>
        </div>
        <div className="submit-block">
            <div className="btn yellow btn-submit" onClick={() => this.SubmitChoice()}><span>{varT('room_services_cta_next')}</span></div>
            <div className="dots"><div></div><div></div></div> 
        </div>
      </div>
    )
  }
}

export default FormContact;