

import React , { Component } from 'react';

class NumberOfItemProduct extends Component {
  constructor(props){
    super(props);
      props.GetInfoNbProduct(this.props.idItemProd, 0, this.props.labelMenuProd);
  }
  state = {
    nbItemProduct : 0
  }
  
  AddNbProd = () => {
    if(this.state.nbItemProduct < 6){
      this.setState({ nbItemProduct: ++this.state.nbItemProduct })
      this.props.GetInfoNbProduct(this.props.idItemProd,this.state.nbItemProduct, this.props.labelMenuProd);
    }
  }
  RedNbProd = () => {
    if(this.state.nbItemProduct > 0){
      this.setState({ nbItemProduct: --this.state.nbItemProduct })
      this.props.GetInfoNbProduct(this.props.idItemProd,this.state.nbItemProduct);
      this.props.GetInfoNbProduct(this.props.idItemProd,this.state.nbItemProduct, this.props.labelMenuProd);
    }
  }

  render() {
    // console.log("props", this.props.idItemProd)
    return (
        <div className="number-select">
            <div className="btn-number btn-moins" onClick={() => this.RedNbProd()}></div>
            <div className="number">{this.state.nbItemProduct}</div>
            <div className="btn-number btn-plus" onClick={() => this.AddNbProd()}></div>
        </div>
    )
  }
}

export default NumberOfItemProduct;