import React , { Component } from 'react';
// import Lottie from 'react-lottie'

class Loader extends Component {

  render() {
    
    return (
      <div className={"loader-block "+ this.props.classLoadName}>
        <div className="content-loader">
          <div className="loader"></div>
        </div>
      </div>
    );
  }
}

export default Loader;
  