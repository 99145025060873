
// import {conf_env} from '../../conf_env'
function getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}
function getUrlParam(parameter, defaultvalue){
    var urlparameter = defaultvalue;
    if(window.location.href.indexOf(parameter) > -1){
        urlparameter = getUrlVars()[parameter];
        }
    return urlparameter;
}

const resortId = getUrlParam('resortId');
const roomId = getUrlParam('roomId') || null;
localStorage.setItem("resortId",resortId);
var  paramUrl = '';
if(roomId == null){
    paramUrl = "?resortId="+resortId;
}else{
    paramUrl = "?resortId="+resortId+"&roomId="+roomId;
    localStorage.setItem("roomId",roomId);
}


const settings_env = {
    API_BASE_URL: window.env.API_BASE_URL,
    API_BASE_URL_POST: window.env.API_BASE_URL_POST,
    API_KEY:window.env.API_KEY,
    API_VERSION:window.env.API_VERSION
};

const version = settings_env.API_VERSION;
const apibase_url = settings_env.API_BASE_URL;
const apibase_url_post = settings_env.API_BASE_URL_POST;

const API_URL = {
    "base" : apibase_url,
    "sendinfo" : apibase_url_post + 'roomservice',
    "resort" : apibase_url + resortId + "?v="+ version,
    "info" : apibase_url + resortId + "/contents?contentType=rubric_room_service&v="+ version,
    "menu_product" : apibase_url + resortId + "/contents?contentType=menu_product&v="+ version,
}

const HEADER_API_URL = {
    "accept_language" : localStorage.getItem('currentLang'),
    "api_key" : settings_env.API_KEY,
}

export {API_URL, resortId, HEADER_API_URL, paramUrl,roomId};

